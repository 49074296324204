import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 2";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "going-live"
    }}>{`Going live`}</h1>
    <p>{`There's a few approaches you can use to make the project live. I recommend Netlify or Zeit.`}</p>
    <p>{`You'll need the following:`}</p>
    <ul>
      <li parentName="ul">{`add your project to github`}</li>
      <li parentName="ul">{`use a netlify or zeit github integration`}</li>
      <li parentName="ul">{`use their webhook to redeploy your project when a new page is created`}</li>
    </ul>
    <p>{`That last part gives you the benefits of static pages. Otherwise you're always re-fetching fresh data :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      